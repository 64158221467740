.job-listing-block {
    text-align: left;
    font-size: 14px;
    max-width: 1400px;
    display: inline-block;
    width: 100%;
}

.job-viewer-nav {
    text-align: right;
}

.job-listing-header {
    font-weight: normal;
    font-size: 22px;
}

.job-item-block {
    display: flex;
    flex-wrap: wrap;
}

.job-item-description {
    display: block;
    margin: 0 10px 20px 0;
    flex-grow:1;
    flex-basis: 80%;
}

.job-item-title {
    font-weight: bold;
    font-size: 18px;
}

.job-item-location {
    margin-top: 0.2em;
    display: flex;
    align-items: center;
    font-weight: normal;
}

.job-item-creation-date {
    display: flex;
    align-items: center;
    font-weight: normal;
    margin-left: 2em;
}

.job-item-content {
    margin-top: 1.3em;
    font-weight: normal;
}

.job-item-action-block {
   display: grid;
   #background: #61dafb;
   flex-grow:1;
   flex-basis:150px;
   text-align: right;
   vertical-align: bottom;

}

.job-item-action-block span{
    align-self: flex-end;
    justify-self: right;
}

.job-item-action-block a{
}

/* Job Viewer Component */

.job-viewer-block {
    text-align: left;
    font-size: 14px;
    max-width: 1400px;
    display: inline-block;
    width: 100%;
}

.job-viewer-title-block {
    text-align: left;
    font-size: 20px;
    margin-top: 20px;
}

.job-viewer-title {
    font-size: 32px;
    font-weight: bold;
}

.job-viewer-location {
    margin-top: 0.5em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: normal;
}

.job-viewer-location-city {
    display: flex;
    align-items: center;
    font-weight: normal;
    padding-right: 30px;
}

.job-viewer-creation-date {
    display: flex;
    align-items: center;
    font-weight: normal;
    color: gray;
}



.job-viewer-section-offerings {
    margin-top: 35px;
}

.job-viewer-section-tasks {
    margin-top: 35px;
}

.job-viewer-section-demands {
    margin-top: 35px;
}

.job-viewer-section-header {
    margin-top: 30px;
    margin-bottom: 0px;
    font-weight: normal;
    font-size: 18px;
}

.job-viewer-section-footer {
    margin-top: 50px;
    margin-bottom: 0px;
    font-weight: normal;
    font-size: 18px;
}

.job-viewer-apply-section {
    margin-top: 40px;
    text-align: center;
    font-weight: normal;
    line-height: 1.7em;
}

.job-viewer-apply-address {
    text-align: left;
    font-size: 16px;
}

.job-viewer-apply-button {
    margin-top: 40px;
    text-align: left;
}


.job-viewer-content {
    font-weight: normal;
    font-size: 18px;
}

/*  Job Apply Form */

.job-apply-form-container {
    padding: 40px;
    margin-top: 50px;
    border: 1px solid;
}
